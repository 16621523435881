@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: normal;
  src:
    local("Helvetica"),
    url("./fonts/Helvetica.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Oblique";
  font-style: normal;
  font-weight: normal;
  src:
    local("Helvetica Oblique"),
    url("./fonts/Helvetica-Oblique.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Compressed";
  font-style: normal;
  font-weight: normal;
  src:
    local("Helvetica Compressed"),
    url("./fonts/helvetica-compressed-5871d14b6903a.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Bold";
  font-style: normal;
  font-weight: normal;
  src:
    local("Helvetica Bold"),
    url("./fonts/Helvetica-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: normal;
  src:
    local("Helvetica"),
    url("./fonts/Helvetica.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Bold Oblique";
  font-style: normal;
  font-weight: normal;
  src:
    local("Helvetica Bold Oblique"),
    url("./fonts/Helvetica-BoldOblique.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Light";
  font-style: normal;
  font-weight: normal;
  src:
    local("Helvetica Light"),
    url("./fonts/helvetica-light-587ebe5a59211.woff") format("woff");
}

body {
  font-family: "Helvetica";
}

.heading-lg {
  font-family: "Helvetica Bold";
  color: var(--gray130);
  font-size: 32.44px;
  font-style: normal;

  line-height: 38.928px;
}

.heading-md {
  font-family: "Helvetica Bold";
  color: var(--gray130);
  font-size: 25.63px;
  font-style: normal;
  line-height: 30.756px;
}

.heading-sm {
  font-family: "Helvetica Bold";
  color: var(--gray130);
  font-size: 20.25px;
  font-style: normal;
  line-height: 24.3px;
}

.title-lg {
  font-family: "Helvetica Bold";
  color: var(--gray90);
  font-size: 18px;
  font-style: normal;
  line-height: 21.6px;
}

.title-sm {
  font-family: "Helvetica Bold";
  color: var(--gray90);
  font-size: 16px;
  font-style: normal;
  line-height: 19.2px;
}

.body-lg {
  color: var(--gray130);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.6px;
}

.body-sm {
  color: var(--gray130);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.2px;
}

.link {
  color: var(--gray130);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.2px;
  text-decoration-line: underline;
}

.label-lg {
  color: var(--gray130);
  font-size: 14.22px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.064px;
}

.label-sm {
  color: var(--gray130);
  font-size: 12.64px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.168px;
}

strong{
    font-family: "Helvetica Bold";
}