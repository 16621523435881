body {
  --purple10: #f1ecff;
  --purple30: #d6c7ff;
  --purple100: #7745ff;
  --purple110: #5d2be5;
  --purple120: #4412cc;
  --white: #ffffff;
  --gray20: #f2f1f4;
  --gray30: #d8d7da;
  --gray90: #727174;
  --gray110: #3f3e41;
  --gray130: #0c0b0e;
  --red10:#fff4f5;
  --red20: #f8d0d5;
  --red100: #db162f;
  --red110: #b61227;
  --green20: #cdede5;
  --green100: #058b68;
  --green110: #046f53;
  --blue20: #dadcff;
  --blue100: #4551ff;
  --blue110: #3a44d5;
  --orange20: #fde8d0;
  --orange100:#dba116;
  --orange110: #b58712;
}
