@import url("design-system/colors.css");
@import url("design-system/typography.css");
@import url("design-system/dimensions.css");
@import url("design-system/forms.css");

/* TO REMOVE */
html,body{ 
height: 100%;
min-height: 100%;
box-sizing: border-box;
margin: 0;
padding: 0; 
max-width: 100vw;
overflow-x:hidden;
}

*{ box-sizing: border-box;}
