.form-row{
    display:flex;
    gap:16px;
}
.input-row{  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap:4px;
    border-radius: 4px;
}

.input-row input,
.input-row select{
    padding: 10px 12px;
    box-sizing: border-box;
    border-radius: 4px;
    border:1px solid var(--gray30);
}

input.size-selector-input{
    width: 56px;
}

input.error{
    border:1px solid var(--red110);
}


input[type=tel]{
    border:0;
}

.PhoneInput{
    width: 100%;
    padding-left: 12px;
    box-sizing: border-box;
    border-radius: 4px;
    border:1px solid var(--gray30);
}